import "./App.css";
import { ComingSooon } from "./modules/coming-soon";

function App() {
  return (
    <div className="App">
      <ComingSooon />
    </div>
  );
}

export default App;
