import goods from "./goods-shop.png";
import inner from "../../assets/sandıkınner.png";
import inner2 from "./logoo.png";
import styles from "./index.module.scss";
export const ComingSooon = () => {
  return (
    <section className={styles.comingsoonPage}>
      <div className={styles.comingsoonContainer}>
        <div style={{ width: "35%" }}>
          <img src={goods} height={"100%"} width={"100%"} alt="lal" />
        </div>
        <div>
          <h1 className={styles.title}>Çok yakında hizmetinizdeyiz</h1>
        </div>
      </div>
      <div className={styles.textContainer}>
        <h4>
          "Good’s Shop: Bellapais Inner Wheel Kulübü tarafından İyilikle Dokunan
          Online Bağış/Alışveriş Platformu"
        </h4>
        <p>
          Sevgiyle kurulan “Goods Shop” sayesinde, gardırobunuzda unutulmuş,
          sevgiyle seçilmiş kıyafetleriniz, artık kadınlara ve çocuklara umut
          olacak!
        </p>
        <h4>Çevre Dostu Alışveriş:</h4>
        <p>
          Good’s Shop, kıyafetlerin ikinci bir şans bulmasını sağlayarak doğayı
          korur. Siz de gardrobunuzdaki giysileri bağışlayarak sürdürülebilir
          moda hareketine katılın.
        </p>
        <h4> Sosyal Dokunuş:</h4>
        <p>
          Elde edilen gelirle, Bellapais Innerwheel Kulübü olarak kadınları ve
          çocukları destekleyen projelere katkıda bulunuyoruz. Her alışverişle,
          sizlere bir başkasının hayatına dokunma fırsatı sunuyoruz.
        </p>
        <h4>İklim Dostu Adımlar:</h4>
        <p>
          Good’s Shop platformu, karbon ayak izini azaltarak, enerji ve su
          tüketimini minimumda tutarak iklim krizinin önlenmesine katkı
          sağlıyor. <br />
          Hep birlikte küçük adımlarla büyük değişimler yaratıyoruz
        </p>
        <p>
          Haydi, Gardırobunu Yeniden Keşfet, Birilerine Umut Ol! Good’s Shop'a
          katıl, hem kıyafetlerini bağışla hem de iyilik ve çevre dostu bir
          alışveriş deneyimi yaşa.
        </p>
      </div>
      <div className={styles.logoContainer}>
        <img
          src={inner2}
          alt="inner-logo"
          width={140}
          height={180}
          className={styles.image}
        />
        <img src={inner} alt="inner-logo" className={styles.image2} />{" "}
        {/* <LanguageSwitcher /> */}
      </div>
    </section>
  );
};
